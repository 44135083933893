// Gatsby supports TypeScript natively!
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Careers = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout>
      <SEO title="Careers" />
      <header className="page-header">
        <div className="header-image-container tablet-and-up">
          <Image fixed={data.tabletAndUpHeaderImage.childImageSharp.fixed} />
        </div>
        <div className="header__inner-container">
          <h1>Careers</h1>
          <div>
            <p>We're looking for dedicated, creative people to join us in making Prasthana more better. Is that <span className="highlight">You</span>?</p>
          </div>
          <Image className="mobile-to-tablet" fixed={data.mobileAndUpHeaderImage.childImageSharp.fixed} />
        </div>
      </header>
      <section className="content highlighted">
        <div className="quote-section">
          <h2>“Qualifications only give you entry. It is competence that ultimately works“</h2>
          <p>- Sadhguru</p>
        </div>
        {posts.map(({node: post}) => (
          <div className="career-card" key={post.id}>
            <h3>{post.frontmatter.title}</h3>
            <p>{post.frontmatter.description} <Link to={post.frontmatter.slug}>Read more...</Link></p>
            <Link className="button secondary is-large" to={post.frontmatter.slug + "#apply"}>Apply</Link>
          </div>
        ))}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query PostQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            description
            slug
            title
          }
        }
      }
    },
    mobileAndUpHeaderImage: file(relativePath: {eq: "careers@3x.png"}) {
      childImageSharp {
        fixed(width: 229, height: 118, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    tabletAndUpHeaderImage: file(relativePath: {eq: "careers@3x.png"}) {
      childImageSharp {
        fixed(width: 360, height: 186, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Careers